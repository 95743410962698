import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";

function disableDrag(e) {
  e.preventDefault();
}

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

document.addEventListener("dragstart", disableDrag, false);
document.addEventListener("dragover", disableDrag, false);
document.addEventListener("drop", disableDrag, false);
