import React from "react";
import "beercss";
import "material-dynamic-colors";
import { Link } from "react-router-dom";

export const N = () => {
  return (
    <nav className="m l left">
      <Link to="#">
        <i>home</i>
        <span>Home</span>
      </Link>
    </nav>
  );
};
