import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { Home } from "../containers/home";

export const R = (props) => {
  return (
    <Router basename={"/"}>
      {props.children}
      <Routes>
        <Route path="/" element={<Home />} exact />
      </Routes>
    </Router>
  );
};
