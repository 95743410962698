import React from "react";

import { N } from "./navbar";
import { R } from "./router";

const App = () => {
  return (
    <R>
      <N />
    </R>
  );
};


export default App;
