import * as React from "react";
import "beercss";
import "material-dynamic-colors";
import { Link } from "react-router-dom";

const isZH = () => {
  const lang = navigator.language.toLowerCase();
  return lang.startsWith("zh");
};
const language = navigator.language;

if (isZH(language)) {
  console.log("用户使用中文语言");
}

function ZhOnly({children}) {
  const is = isZH(language);
  return is ? children : null;
}

export const Home = () => {
  return (
    <main className="responsive">
      <article className="scroll">
        <p>
        This page is not ready yet, it is being refactored.
        </p>
        <footer className="fixed">
          <h5 className="no-margin">Malonan Team Global && SG</h5>
          <ZhOnly>
            <Link target="_blank" to="https://icp.gov.moe/?keyword=20210007"><p>Moe Record, 20210007</p></Link>
          </ZhOnly>
        </footer>
      </article>
    </main>
  );
};
